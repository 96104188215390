import {request, request_op} from "../plugins/axios";

//获取微信用户信息
export function openUserInfoApi (params) {
  return request_op({
      url: '/openUserInfo/redPacket',
      method: 'GET',
      params: params
  })
}

// 获取红包信息
export function redPacketIndex (params) {
  return request({
      url: '/redPacket/index',
      method: 'get',
      params: params
  })
}

// 领取红包
export function receive (params) {
  return request({
      url: 'redPacket/receive',
      method: 'post',
      data: params
  })
}