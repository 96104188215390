<template>
  <div class="receiveBag" v-if="showPage">
    <van-overlay :show="show">
      <div class="redBag">
        <div class="arc"></div>
        <div class="info">
          <!-- <div class="title">xxxxx发出的红包</div> -->
          <div class="desc" v-if="redPacket.wishing">{{ redPacket.wishing }}</div>
          <div class="desc" v-else>恭喜发财，大吉大利</div>
        </div>
        <div class="redmid" v-if="redPacket.received == 0" @click="receiveBag">開</div>
        <div class="redmid received" v-else>已领取</div>
      </div>
    </van-overlay>
  </div>
  <div v-else>
    获取失败，请重新进入
  </div>
</template>
<script>
import { redPacketIndex, openUserInfoApi, receive } from "@/api/receiveBag";
export default {
  data () {
    return {
      show: true,
      showPage: false,
      redPacketId: '', // 红包id
      //用户微信信息
      weChatUserNews: {},
      // 红包信息
      redPacket: {}
    }
  },
  created () {
    this.redPacketId = this.$route.query.id
    this.getOpenUserInfo()
    this.getIndex()
  },
  mounted () {
    document.title = '领取红包'
  },
  methods: {
    getOpenUserInfo() {
      let that = this
      openUserInfoApi({ id: that.redPacketId }).then((res) => {
        if (res.data.openid === undefined) {
          let redirectUrl = '/auth/roomClockIn?id=' + that.id + '&target=' + encodeURIComponent(that.url);
          that.$redirectAuth(redirectUrl)
          return
        }
        this.weChatUserNews = res.data
        this.getIndex()
      })
    },
    getIndex () {
      const params = {
        id: this.redPacketId,
        contactId: ''
      }
      redPacketIndex(params).then((res) => {
        if (res.code == 200) {
          this.redPacket = res.data
          this.showPage = true
        }
      })
    },
    receiveBag () {
      const data = {
        id: this.redPacketId,
        contactId: ''
      }
      receive(data).then(res => {
        if (res.code == 200) {
          this.$message.success('领取成功')
        } else {
          this.$message.info(res.msg)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
}
.receiveBag {
  .redBag {
    position: relative;
    margin: 120px auto;
    width: 350px;
    height: 520px;
    background-color: #d03835;
    border-radius: 10px;
    overflow: hidden;
    .arc {
      position: absolute;
      background-color: #ed574f;
      border-radius: 50%;
      height: 400px;
      width: 100%;
      transform: scale(1.4) rotate(90deg) translateX(-30px);
    }
    .info {
      position: relative;
      color: #f2e3b8;
      text-align: center;
      margin: 100px auto;
      .title {
        font-size: 20px;
      }
      .desc {
        margin-top: 6px;
        font-size: 24px;
      }
    }
    .redmid {
      position: absolute;
      width: 140px;
      height: 140px;
      margin: auto;
      border-radius: 50%;
      background: #ebcc9d;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.5);
      font-size: 50px;
      text-align: center;
      line-height: 140px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 60px;
      &.received {
        font-size: 30px;
      }
    }
  }
}
</style>